import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation resources
const resources = {
  en: {
    translation: {
      "switchLabel": "EN",
      "welcomeLabel": "LLM Insight Expert",
      "description": "The intelligent assistant for ad hoc analysis",
      "summary": "The AI-powered tool enables effortless data insights and analysis even without data science expertise. Using large language models (LLMs), the LLM Insight Expert performs data analysis based on a natural language query.",
      "login": "Login",
      "signup": "Sign Up",
      "menu1_name": "Introduction",
      "menu2_name": "Data Uploading",
      "menu3_name": "Prompting",
      "imprint": "Imprint",
      "privacyPolicy": "Privacy Policy",
      "termofUse": "Terms of Use",
      "logout": "Logout",
      "metadata_table_name": "Table Name",
      "metadata_table_status": "Status",
      "metadata_configuration_status_title": "Metadata Configuration Status",
      "metadata_configuration_status_ok": "Configured",
      "metadata_configuration_status_not_configured": "Not Configured",
      "menu1_section1_title": "LLM Insight Expert - The intelligent assistant for ad hoc analysis",
      "menu1_section1_summary": "Companies are often faced with the challenge of investing considerable resources in order to gain business-relevant insights from large and complex data sets. Extracting such information to analyse sales trends, for example, usually requires special skills and a deep understanding of data science. This is where the AI-supported assistant LLM Insight Expert comes in and offers an innovative solution for versatile use in different areas of the company. <br /><br />Which customer group has purchased our product XY most frequently in the past two summers? Did special offers in German-speaking countries play a role in this?« Instead of poring over Excel sheets, company data and marketing documents and commissioning IT specialists with complex database queries, the sales employee types her questions in natural language into the chat field of a Large Language Model (LLM).Seconds later, she receives a well-founded answer, supplemented by a clear graphical presentation of the queried data.The AI tool overcomes the existing challenges of working with data and thus creates effortless access to important business information for all employees.By integrating various language models, users of the AI tool have the opportunity to query their data sets in natural language and try out different models in this use case.To do this, the desired data is first uploaded in CSV format and then the metadata is edited.Then you can start chatting with your own data.If required, individual queries can be downloaded as reports in PDF format.",
      "menu1_section2_title": "Data Upload: Simplified uploading and maintenance of metadata",
      "menu1_section2_summary": "In the section Data Uploading, there are two possibilities for the users:<br /><br />1. Use of the application with own data sets (CSV). Thanks to the support of CSV file formats, users can easily import various data sets directly into the system. The user interface of the AI tool serves to manage the files, with which, for example, metadata can be updated and individual columns can be provided with descriptive labels. This clarifies the content and context of the own data and creates the prerequisites for more accurate and meaningful insights. Here, the data are not stored, but deleted with the logout from the application. The language model only processes the schema of the data. The values in the data sets are not processed by the language model.<br /><br />2. Use of the application with a test data set. To try out the AI tool, our test data sets can also be used. Using the 'Use Test Data' function, the users are automatically redirected to the chat interface and can ask their questions.<br /><br />The stored test data sets include:<br />- Product data for fashion goods<br />- Sales data of these products<br />- Inventory data of these products<br />- Location data of stores",
      "menu1_section3_title": "Prompting: Natural Language Data Interaction",
      "menu1_section3_summary": "The Prompting section of the LLM Insight Expert is designed for an uncomplicated and direct data query. For this, users can enter queries in natural language within a chat interface, which are then automatically translated into the database language SQL by the system. The resulting data retrieval is processed and the answers are presented in a clear and relevant structure: Thus, the system can provide simple data set overviews as well as answer more complex queries from different data sets in the context of the respective question. Depending on the type of data available, the answers are displayed in tables and can be visualized by various chart types. This includes, for example, bar, line, pie charts or geographic maps for location data. This functionality of the LLM Insight Expert transforms the data interaction into a more efficient and user-friendly experience and closes the gap between natural language and database queries. Limitation: The AI application is designed to perform database queries (SQL) through natural language and display the information stored in the data sets according to the request. The system is not suitable, however, to perform independent analyses and interpretations of the data. Nor does the AI provide conclusions from the data.",
      "menu1_section4_title": "PDF Reporting: Automatic Documentation",
      "menu1_section4_summary": "With this feature, users can convert the results of their data queries into PDF documents. After the data analysis through the interaction in natural language, the system can automatically compile the results, including text data and visualizations, in a formatted report. This process facilitates the efficient creation of reports that are ready for review, sharing or archiving, and thus increases the productivity of employees and the communication of insights.",
      "menu2_btn_check_metadata": "Check Metadata",
      "menu2_btn_use_testdata": "Use test data",
      "menu2_btn_use_testdata_or": "OR",
      "menu2_inst": "Upload the data files in CSV format you want to interact with in natural language",
      "menu2_dropzone_msg": "Drag 'n' drop CSV file(s) here, or click to select",
      "menu2_selecedFile_msg": "Selected Files:",
      "menu2_selectedfile_name": "File Name",
      "menu2_selectedfile_action": "Actions",
      "menu2_upload_all_btn": "Upload All",
      "menu2_metadata_tooltip": "Add Metadata Description",
      "menu2_remove_file_tooltip": "Remove file",
      "menu2_metadadesc_title": "Metadata Description",
      "menu2_configure_metadata": "Configure Metadata",
      "menu2_metadata_table_column": "Columns",
      "menu2_metadata_table_desc": "Description",
      "menu2_save": "Save",
      "menu2_cancel": "Cancel",
      "menu2_nofile": "You have not uploaded any files",
      "menu3_summary": "Please ask your questions. I will translate them into SQL queries and provide you with the relevant information.",
      "menu3_prompt_sample_ques": "Sample Questions",
      "menu3_prompt_explain_dataset_title": "Where are my company's shops?",
      "menu3_prompt_explain_dataset_des": "Show me the locations on the map",
      "menu3_prompt_rows_cnt": "Which products have the most sales?",
      "menu3_prompt_rows_cnt_inst": "Show me the top 10",
      "menu3_prompt_selling_products": "Which products have the highest stock levels?",
      "menu3_prompt_selling_top_products": "Show me the top 10 products and prepare all relevant information about the products",
      "menu3_prompt_selling_category": "Which colours are the most popular?",
      "menu3_prompt_selling_category_list": "Show me the top 3",
      "menu3_new_prompt": "Add new prompt",
      "uploadCsvRequired": "Upload CSV File Required",
      "uploadCsvInstruction": "You need to upload a CSV file to proceed to the prompting page",
      "upload_instructions_title": "How to upload data:",
      "upload_instructions_step1": "1. Upload the data files in CSV format you want to interact with in natural language or use test data that is already prepared for the usage. The test data consists of four CSV tables and represents retail data (fashion product data, sales figures related to these products, stock data related to the products, location data of the shops). If you choose this option, you will be automatically redirected to the prompting interface. You are ready to ask your questions.",
      "upload_instructions_step2": "2. Once you dropped your files click on »Upload all« to finalize your upload.",
      "upload_instructions_step3": "3. Click on the blue icon »Add Metadata description« to edit the description of columns if needed. A wrong description can lead to misinterpretations by the large language model.",
      "upload_instructions_step4": "4. Click »Save«. You are ready and can start asking questions about your data in the tab »Prompting«"
    }
  },
  de: {
    translation: {
      "switchLabel": "DE",
      "welcomeLabel": "LLM Insight Expert",
      "description": "Der intelligente Assistent für Ad-hoc-Datenanalysen",
      "summary": "Das KI-gestützte Tool ermöglicht mühelose Dateneinblicke und Analysen auch ohne Data-Science-Expertise. Mithilfe großer Sprachmodelle (engl. LLMs) führt der LLM Insight Expert Datenanalysen auf Basis einer natürlichsprachlichen Abfrage durch.",
      "login": "Anmelden",
      "signup": "Registrieren",
      "menu1_name": "Einführung",
      "menu2_name": "Daten hochladen",
      "menu3_name": "Prompting",
      "imprint": "Impressum",
      "privacyPolicy": "Datenschutz",
      "termofUse": "Nutzungsbedingungen",
      "logout": "Abmeldung",
      "metadata_table_name": "Name der Tabelle",
      "metadata_table_status": "Status",
      "metadata_configuration_status_title": "Status der Metadatenkonfiguration",
      "metadata_configuration_status_ok": "Konfiguriert",
      "metadata_configuration_status_not_configured": "Nicht konfiguriert",
      "menu1_section1_title": "LLM Insight Expert - Der intelligente Assistent für Ad-hoc-Analysen",
      "menu1_section1_summary": "Unternehmen stehen oft vor der Herausforderung, erhebliche Ressourcen zu investieren, um aus großen und komplexen Datensätzen geschäftsrelevante Erkenntnisse zu gewinnen. Das Extrahieren solcher Informationen für die Analyse von z. B. Verkaufstrends erfordert in der Regel spezielle Fähigkeiten und ein tiefes Verständnis von Data Science. Hier setzt der KI-gestützte Assistent LLM Insight Expert an und bietet eine innovative Lösung zum vielseitigen Einsatz in unterschiedlichen Unternehmensbereichen. <br /><br /> »Welche Kundengruppe hat in den vergangenen beiden Sommern unser Produkt XY am häufigsten erworben? Haben im deutschen Sprachraum Sonderangebote eine Rolle dabei gespielt?« Anstatt über Excel-Sheets, Unternehmensdaten und Marketing-Unterlagen zu grübeln und parallel Informatik-Fachleute mit aufwändigen Datenbank-Abfragen zu beauftragen, tippt die Sales-Mitarbeiterin ihre Fragen in natürlicher Sprache ins Chat-Feld eines Large Language Models (LLM) ein. Sekunden später erhält sie eine fundierte Antwort, ergänzt durch eine übersichtliche grafische Aufbereitung der abgefragten Daten. Das KI-Tool überwindet dabei die bestehenden Herausforderungen bei der Arbeit mit Daten und schafft somit einen mühelosen Zugang zu wichtigen Geschäftsinformationen für alle Mitarbeitenden. Durch die Einbindung verschiedener Sprachmodelle haben die Nutzer*innen des KI-Tools die Möglichkeit, ihre Datensätze in natürlicher Sprache zu befragen und unterschiedliche Modelle in diesem Anwendungsfall auszuprobieren. Dafür werden die gewünschten Daten zunächst im CSV-Format hochgeladen und anschließend die Metadaten bearbeitet. Dann kann das Chatten mit den eigenen Daten beginnen. Bei Bedarf können einzelne Abfragen als Reports im PDF-Format heruntergeladen werden.",
      "menu1_section2_title": "Daten-Upload: Vereinfachtes Hochladen und Pflege der Metadaten",
      "menu1_section2_summary": "Im Bereich Data Upload bieten sich zwei Möglichkeiten für die Benutzer*innen:<br /><br />1. Nutzung der Anwendung mit eigenen Datensätzen (CSV). Dank der Unterstützung von CSV-Dateiformaten können Benutzer*innen mühelos verschiedene Datensätze direkt in das System importieren. Die Benutzeroberfläche des KI-Tools dient der Verwaltung der Dateien, mit denen beispielsweise Metadaten aktualisiert und einzelne Spalten mit beschreibenden Beschriftungen versehen werden können. Dies verdeutlicht den Inhalt und den Kontext der eigenen Daten und schafft die Voraussetzungen für genauere und aussagekräftigere Erkenntnisse. Hierbei werden die Daten nicht gespeichert, sondern mit der Abmeldung aus der Anwendung gelöscht. Das Sprachmodell verarbeitet lediglich das Schema der Daten. Die Werte in den Datensätzen werden hingegen nicht durch das Sprachmodell verarbeitet.<br /><br />2. Nutzung der Anwendung mit einem Testdatensatz. Zum Ausprobieren des KI-Tools können auch unsere Testdatensätze genutzt werden. Über die Funktion »Testdaten verwenden« werden die Nutzer*innen automatisch in die Chatoberfläche weitergeleitet und können ihre Fragen stellen.<br /><br />Die hinterlegten Testdatensätze beinhalten:<br />- Produktdaten für Modewaren<br />- Verkaufsdaten dieser Produkte<br />- Lagerdaten dieser Produkte<br />- Standortdaten von Läden",
      "menu1_section3_title": "Prompting: Dateninteraktion in natürlicher Sprache",
      "menu1_section3_summary": "Der Abschnitt »Prompting« des LLM Insight Expert ist für eine unkomplizierte und direkte Datenabfrage konzipiert. Dafür können Benutzende innerhalb einer Chat-Oberfläche Abfragen in natürlicher Sprache eingeben, die dann vom System automatisch in die Datenbanksprache SQL übersetzt werden. Der resultierende Datenabruf wird verarbeitet und die Antworten werden übersichtlich und relevant strukturiert: So kann das System einfache Datensatzübersichten bereitstellen sowie komplexere Abfragen aus verschiedenen Datensätze im Kontext der jeweiligen Frage beantworten. Je nach Art der vorliegenden Daten werden die Antworten in Tabellen dargestellt und können durch verschiedene Diagrammtypen visualisiert werden. Dies umfasst z. B. Balken-, Linien-, Kreisdiagramme oder geografische Karten für Standortdaten. Diese Funktionalität des LLM Insight Expert verwandelt die Dateninteraktion in eine effizientere und benutzerfreundliche Erfahrung und schließt die Lücke zwischen natürlicher Sprache und Datenbankabfragen. Limitation: Die KI-Anwendung ist darauf ausgerichtet, Datenbankabfragen (SQL) durch natürliche Sprache durchzuführen und die in den Datensätzen hinterlegten Informationen gemäß der Anfrage anzuzeigen. Das System ist hingegen nicht geeignet, um eigenständige Analysen und Interpretationen der Daten durchzuführen. Auch Schlussfolgerungen aus den Daten werden nicht durch die KI bereitgestellt.",
      "menu1_section4_title": "PDF-Berichterstellung: Automatische Dokumentation",
      "menu1_section4_summary": "Mit dieser Funktion können Benutzer*innen die Ergebnisse ihrer Datenabfragen in PDF-Dokumente konvertieren. Nach der Datenanalyse durch die Interaktion in natürlicher Sprache kann das System die Ergebnisse, einschließlich Textdaten und Visualisierungen, automatisch in einem formatierten Bericht zusammenstellen. Dieser Prozess erleichtert die effiziente Erstellung von Berichten, die zur Überprüfung, zum Teilen oder zur Archivierung bereit sind, und steigert auf diese Weise die Produktivität von Mitarbeitenden und die Kommunikation von Erkenntnissen.",
      "menu2_btn_check_metadata": "Metadaten kontrollieren",
      "menu2_btn_use_testdata": "Testdaten verwenden",
      "menu2_btn_use_testdata_or": "ODER",
      "menu2_inst": "Laden Sie die Datendateien im CSV-Format hoch, mit denen Sie in natürlicher Sprache interagieren möchten",
      "menu2_dropzone_msg": "CSV-Datei(en) hierher ziehen oder klicken, um diese auszuwählen",
      "menu2_selecedFile_msg": "Ausgewählte Dateien:",
      "menu2_selectedfile_name": "Dateiname",
      "menu2_selectedfile_action": "Aktion",
      "menu2_upload_all_btn": "Alle Dateien hochladen",
      "menu2_metadata_tooltip": "Metadatenbeschreibung hinzufügen",
      "menu2_remove_file_tooltip": "Datei löschen",
      "menu2_metadadesc_title": "Metadatenbeschreibung",
      "menu2_configure_metadata": "Metadaten konfigurieren",
      "menu2_metadata_table_column": "Rubriken",
      "menu2_metadata_table_desc": "Beschreibung",
      "menu2_save": "Speichern",
      "menu2_cancel": "Abbrechen",
      "menu2_nofile": "Sie haben keine Dateien hochgeladen",
      "menu3_summary": "Bitte stellen Sie Ihre Fragen. Ich werde sie in SQL-Abfragen übersetzen und Ihnen die entsprechenden Informationen aus den Datensätzen zur Verfügung stellen.",
      "menu3_prompt_sample_ques": "Beispiel-Fragen",
      "menu3_prompt_explain_dataset_title": "Wo sind die Läden meines Unternehmens?",
      "menu3_prompt_explain_dataset_des": "Zeige mir die Orte auf der Karte",
      "menu3_prompt_rows_cnt": "Welche Produkte haben die meisten Verkäufe?",
      "menu3_prompt_rows_cnt_inst": "Zeige mir die Top 10",
      "menu3_prompt_selling_products": "Welche Produkte haben den höchsten Lagerstand?",
      "menu3_prompt_selling_top_products": "Zeige mir die Top 10 Produkte und bereite mir alle relevanten Informationen zu den Produkten auf",
      "menu3_prompt_selling_category": "Welche Farben sind am beliebtesten?",
      "menu3_prompt_selling_category_list": "Zeig mir die Top 3",
      "menu3_new_prompt": "Neue Eingabeaufforderung hinzufügen",
      "uploadCsvRequired": "CSV-Datei erforderlich",
      "uploadCsvInstruction": "Sie müssen eine CSV-Datei hochladen, um zur Prompting-Seite zu gelangen",
      "upload_instructions_title": "So laden Sie Daten hoch:",
      "upload_instructions_step1": "1. Laden Sie die Daten im CSV-Format hoch, mit denen Sie in natürlicher Sprache interagieren möchten, oder verwenden Sie Testdaten, die bereits zur Verfügung stehen. Die Testdaten bestehen aus vier CSV-Tabellen und repräsentieren Einzelhandelsdaten (Produktdaten der Mode, Verkaufszahlen zu diesen Produkten, Bestandsdaten der Produkte, Standortdaten der Geschäfte). Wenn Sie diese Option wählen, werden Sie automatisch zur Prompting-Seite weitergeleitet. Sie sind bereit, Ihre Fragen zu stellen.",
      "upload_instructions_step2": "2. Sobald Sie Ihre Dateien hochgeladen haben, klicken Sie auf »Alle hochladen«, um Ihren Upload abzuschließen.",
      "upload_instructions_step3": "3. Klicken Sie auf das blaue Symbol »Metadaten kontrollieren«, um die Beschreibung der Spalten bei Bedarf zu bearbeiten. Eine falsche Beschreibung kann zu Fehlinterpretationen durch das große Sprachmodell führen.",
      "upload_instructions_step4": "4. Klicken Sie auf »Speichern«. Sie sind bereit und können im Tab »Prompting« Fragen zu Ihren Daten stellen."
    }
  }
};

// Initialize i18next
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'de', // Default language
    fallbackLng: 'en', // Fallback language in case the selected language is not available
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
